import React from "react";
import Artykul from "../components/artykul";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/Jaki-CRM-dla-malej-firmy.png";
import Container from "../components/container";
import Lightbox from "../components/lightBox";
import MultiStepFormButton from "../components/MultiStepForm/MultiStepFormButton";

export const query = graphql`
 {
    zdjecie1: file(
      relativePath: {
        eq: "porownanie-crm1.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kolo: file(
      relativePath: {
        eq: "kolo.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    karta: file(
      relativePath: {
        eq: "karta-cien.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kanban: file(
      relativePath: {
        eq: "kanban-cien.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lejek: file(
      relativePath: {
        eq: "lejek-cien.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    konwersja: file(
      relativePath: {
        eq: "konwersja-cien.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const JakiCRM = ({ data }) => {
  return (
    <Artykul
      title="Jaki CRM dla małej firmy?"
      articleImage={obrazekArtykulu}
      keywords={["jaki CRM"]}
      articleImageAlt="jaki CRM wybrać"
      metaTitle="Jaki CRM dla małej firmy? Doradzają specjaliści"
      metaDescription="Jaki CRM dla małej firmy? Jak dopasować CRM do potrzeb? ✅ QuickCRM, prosty CRM dla dynamicznych zespołów już od 5 zł. Sprawdź za darmo!"
    >
    <Container className="container container--text">
    <p>Wiele przedsiębiorstw, nawet tych małych, w&nbsp;pewnym momencie swojego rozwoju mierzy 
    się z bardzo istotnym pytaniem – “jaki&nbsp;CRM wybrać?”. Dla każdej firmy jest to jedna 
    z&nbsp;ważniejszych decyzji, która wymaga zespołowych ustaleń oraz dokładnego poznania 
    ofert producentów oprogramowań informatycznych dla biznesu. Przed takim wyzwaniem 
    stoją również małe przedsiębiorstwa poszukujące idealnego rozwiązania dopasowanego 
    do rozmiaru prowadzonej działalności.
    </p>
    <p>Jesteś przedstawicielem takiej organizacji? Zastanawiasz się wraz z współpracownikami 
    nad wykorzystaniem CRM-a? Nie wiecie jakie elementy powinniście brać pod uwagę podczas 
    wyboru?
    </p>
    <p>Zapraszamy do lektury, która odpowie na tytułowe pytanie.
    </p>
    </Container>
    <Container className="container container--text">
    <h2 style={{
      paddingBottom:10}}>Zacznij od tego, czym jest CRM</h2>
        <p>
        CRM, czyli skrót od anglojęzycznego pojęcia „Customer Relationship
        Management”, dosłownie oznacza „zarządzanie relacjami z&nbsp;klientem”. 
        Termin ten odnosi się do zarządzania więziami zawieranymi w&nbsp;obszarze 
        biznesowym. Rozwijając koncepcję CRM, są to wszystkie działania 
        podejmowane przez firmę, których celem jest uzyskanie i utrzymanie 
        dobrych relacji z&nbsp;obecnymi i potencjalnymi klientami.
        </p>
      <p>
        Wiesz już czym jest CRM i jaka jest jego rola. Jeśli są to Twoje początki 
        z tym zagadnieniem, zastanawiasz się zapewne, jakie narzędzia mogą Ci pomóc 
        w&nbsp;zarządzaniu relacjami z klientami. Z&nbsp;pomocą przychodzi program CRM, który 
        może pomóc Ci w optymalizacji tego obszaru Twojego biznesu przy jednoczesnym 
        poszerzaniu horyzontów. Może sprawić, że znacznie zwiększysz grono swoich 
        klientów, a dodatkowo zachowasz ten sam wysoki poziom relacji z&nbsp;nimi. <strong>Taka 
        inwestycja zwróci się bardzo szybko w postaci zadowolonych klientów, którzy mają 
        świadomość, że ktoś ich słucha i reaguje na potrzeby w mgnieniu oka.</strong>
        </p>
       <p>Zupełnie nowi klienci bez dodatkowych kosztów marketingowych są na wyciągnięcie 
       ręki! Co więcej, dane o&nbsp;kontrahentach pomogą Ci podejmować lepsze decyzje biznesowe. 
       W jaki sposób? Z systemem CRM zyskujesz nowe możliwości!
       </p>
       <p
        style={{
          textAlign: "center"
        }}
      ><MultiStepFormButton></MultiStepFormButton></p>
      </Container>
      <Container className="container container--text">
    <h2 style={{
      paddingBottom:10}}>Jaki CRM? Idealnie dopasowany</h2>
      <p>Masz już świadomość, że z jednej strony CRM to filozofia dotycząca budowania 
      relacji z kontrahentami, a&nbsp;z&nbsp;drugiej narzędzie, które do tego służy. Poszukując 
      odpowiedzi na tak ważne pytanie – jaki CRM wybrać, to nie jedyna wiedza, którą 
      musisz posiadać. Istotne, a może wręcz <strong>najistotniejsze jest dokładne poznanie 
      specyfiki swojego własnego biznesu i prowadzonych działań.</strong> Oznacza to, że 
      przed rozpoczęciem poszukiwań systemu CRM, zadaniem Twoim i Twoich współpracowników 
      powinno być określenie Waszych potrzeb i oczekiwań.
      </p>
      <p>Pytania, na które warto znać odpowiedź to:</p>
      <p>
        <ol>
          <li>Jak pozyskujecie klientów?</li>
          <li>Jak kontaktujecie się z kontrahentami?</li>
          <li>
            Jakie informacje są dla Waszej firmy ważne i jak chcecie je gromadzić?
          </li>
          <li>Jakie procesy sprzedażowe występują w&nbsp;Waszej firmie?</li>
          <li>W czym ma Wam pomóc CRM?</li>
          <li>Jakich rezultatów oczekujecie?</li>
        </ol>
      </p>
      <p>Uporządkowanie tego typu informacji pomoże w znalezieniu systemu odpowiednio 
      dopasowanego do typu prowadzonej działalności i zachodzących w organizacji procesów. 
      Jest to istotne ze względu na to, że rynek programów CRM jest obecnie bardzo nasycony, 
      a każdy z nich wyróżnia się czymś innym.
      </p>
      <p>
      Dodatkowo, takie działanie to dobra okazja, by zweryfikować dotychczasowy sposób 
      realizacji procesów sprzedażowych. Dzięki usystematyzowanej wiedzy możesz sprzedawać 
      stałym klientom produkty lub usługi uzupełniające, a lepsze trafienie w&nbsp;potrzeby 
      klienta to też większa szansa na polecenia Twojej firmy innym osobom. 
      </p>
      </Container>
      <Container className="container container--text">
    <h3 style={{
      paddingBottom:10}}>Jaki CRM dla małej firmy – przykład idealnego dopasowania</h3>
      <p>Określenie własnych potrzeb jest istotne, ponieważ pozwoli na wybór systemu, 
      który będzie posiadał wszystkie kluczowe funkcje ze względu na rodzaj prowadzonej 
      działalności i rozmiar przedsiębiorstwa.
      </p>
      <p>Jak w praktyce działa takie dopasowanie? Poznaj Tomka i jego dylemat związany 
      z&nbsp;pytaniem – jaki CRM dla małej firmy?
      </p>
      <p>
        <i>
          Tomek prowadzi 5 osobową firmę, sprzedaje usługi consultingowe 
          i stawia na rozwój swojej nowej działalności. Obecnie nie korzysta 
          z oprogramowania CRM - odwiedza swoich klientów w ich firmach oraz
          dodatkowo skupia się na kontaktach telefonicznych. Ma
          uporządkowany proces sprzedaży - wyznaczył konkretne etapy: nowy
          kontakt, analiza potrzeb, ofertowanie i&nbsp;zamykanie.
        </i>
        </p>
        <p>
        Rozłóżmy ten krótki opis na elementy pierwsze i ustalmy najważniejsze fakty.
      </p>
      <p>
        <ol>
          <li>
            Tomek prowadzi małą firmę i dopiero rozwija sprzedaż. Nie korzysta z&nbsp;systemu 
            CRM – w tym momencie <strong> nie potrzebuje dedykowanego rozwiązania, które
              wiąże się również z&nbsp;większym kosztem.</strong>
          </li>
           <br />
          <li>
            Tomek odwiedza klientów w ich firmach i&nbsp;ważny jest dla niego kontakt
            telefoniczny – oznacza to, że potrzebuje on wersji mobilnej
            systemu, aby zawsze mieć dostęp do informacji o kliencie oraz całej
            historii ich współpracy. Mobilność zapewni także <strong>możliwość 
            stworzenia notatki bezpośrednio w trakcie lub tuż po spotkaniu,</strong> dzięki 
            czemu Tomek o&nbsp;niczym nie zapomni.
          </li>
           <br />
          <li>
            Tomek ma przemyślany cały proces sprzedaży, nie chce więc, aby CRM
            narzucał mu coś innego.{" "}
            <strong>
              Ważna okazuje się elastyczność systemu, np. możliwość tworzenia
              kampanii sprzedażowych z własnymi etapami, celami i atrybutami.
            </strong>
          </li>
          <br />
          <li>
            Celem Tomka jest rozwój – może się okazać, że rozwiązanie, które
            zaspokaja w pełni jego potrzeby w tym momencie, w przyszłości będzie
            niewystarczające. To naturalne. Wraz z rozwojem firmy, będzie
            potrzebował dodatkowych funkcjonalności. Tomek powinien zwrócić uwagę na to, 
            czy producent systemu, który chce wybrać, może w&nbsp;przyszłości <strong>zaproponować 
            mu przejście na wyższy plan, inne rozwiązanie lub dedykowany system.
            </strong>
          </li>
        </ol>
      </p>
      <p>Znasz już Tomka, sposób jego działania i&nbsp;oczekiwania. W podobny sposób możesz 
      określić swoje własne potrzeby.
      </p>
      <p
        style={{
          textAlign: "center"
        }}
      ><MultiStepFormButton></MultiStepFormButton></p>
      <p>Przejdźmy jednak do kolejnego etapu – wyboru. Po sformułowaniu swoich wymagań 
      Tomek stanął przed najważniejszym pytaniem – jaki CRM wybrać. Zaczął poszukiwania 
      systemu i wyselekcjonował dwie propozycje, których uproszczona specyfika została 
      przedstawiona na grafice poniżej.
      </p>
      <Lightbox
                style={{
                  maxWidth: 800,
                  margin: "0 auto",
                  paddingTop:20,
                }}
                images={[data.zdjecie1]}
                alts={["Porównanie CRM"]}
              />
</Container>
      <Container className="container container--text">
      <p>
        „CRM 2” posiada dodatkowe możliwości takie jak pakiet SMS czy
        integracje. Tomek przeczytał na wielu stronach, że są to bardzo
        potrzebne funkcje. Jaki CRM wybrał?
        </p>
        <p>
        Tomek znał dokładnie swoje potrzeby i&nbsp;wiedział, które cechy są dla niego
        najważniejsze na obecnym etapie działalności. Pakiet SMS oraz integracje to bardzo 
        ciekawe funkcjonalności, ale na ten moment Tomek ich nie potrzebuje. Zależy mu 
        natomiast na opcjach konfiguracji - dostosowania systemu do własnych potrzeb.
        Znaczenie ma również niższa cena, a&nbsp;dodatkowo Tomek doczytał, że producent
        pierwszego CRM-u oferuje mu w przyszłości możliwości rozwojowe. Decyzja
        stała się prosta – wygrał CRM 1.
      </p>
      <p>
        <i>
          <strong>
            Pamiętaj o przykładzie Tomka podczas poszukiwań CRM-a. Zdefiniuj swoje 
            potrzeby i kieruj się nimi przy wyborze rozwiązania, dodatkowo zwracaj 
            uwagę na to, jakie możliwości rozwojowe oferuje Ci producent.
          </strong>
        </i>
      </p>
      <br />
      <center>
        <iframe
          title="jaki-crm"
          src="https://www.youtube.com/embed/5OxHON8h_JE"
          width="720"
          height="480"
          frameborder="0"
          allowfullscreen="allowfullscreen"
        />
      </center>
      </Container>
      <Container className="container container--text">
    <h2 style={{
      paddingBottom:10}}>Na co zwracać uwagę?</h2>
      <p>
        Jak wynika z historii Tomka, wybór w dużej mierze zależy od potrzeb 
        i oczekiwań, które powiązane są z Twoją firmą. Istnieją jednak pewne 
        elementy, na które warto zwrócić uwagę przy każdym pytaniu typu “jaki CRM?”.
       </p>
       </Container>
       <Container className="container container--text">
<div className="text-answers">
<div className="text-block">
<h3>Wersja Software as a Service</h3>
<p>
            Wersja Software as a Service (w skrócie SaaS, z&nbsp;ang. oprogramowanie jako usługa) oznacza, że <strong> system przechowywany 
            jest w chmurze, na serwerze dostawcy</strong>, a Ty korzystasz z&nbsp;niego za pomocą
            Internetu. Nie musisz instalować niczego na własnym komputerze,
            wszystko robi za Ciebie dostawca programu CRM.
          </p>
          <h3>Mobilność</h3>
          <p>
            Pytasz jaki crm? Oczywiście, że mobilny. Mobilność w obecnych czasach 
            jest bardzo istotna. Z&nbsp;pewnością nie wyobrażasz sobie braku 
            możliwości <strong>użytkowania systemu z&nbsp;wykorzystaniem urządzeń 
            mobilnych</strong>, które towarzyszą Tobie na co dzień. Szukaj 
            rozwiązania, które będzie dostosowane do takiej możliwości.
          </p>
          <h3>Intuicyjność</h3>
          <p>
           System CRM będzie stosowany w Twojej firmie pewnie codziennie, 
           dlatego kluczowa jest jego <strong>prostota obsługi</strong>. Jeśli masz 
           problem ze znalezieniem funkcji, albo nie możesz dostosować pulpitu pracy, 
           czyli miejsca, w którym powinny znajdować się kluczowe dla Ciebie informacje 
           potrzebne każdego dnia – zastanów się, czy chcesz tracić czas (tym samym 
           pieniądze) swój i&nbsp;pozostałych pracowników.
          </p>
          <h3>Rozwój</h3>
          <p>
            Sprawdź dostawcę oprogramowania i&nbsp;możliwości jakie oferuje w przypadku 
            rozwoju Twojej firmy. W&nbsp;ofercie znajduje się <strong>możliwość wyższego 
            pakietu, inne rozwiązanie, a nawet dedykowany system?</strong> Idealnie. 
            Wybierz system od takiego dostawcy, ponieważ da Ci to pewność, że 
            w&nbsp;przyszłości, gdy pojawi się taka potrzeba, otrzymasz wsparcie, 
            które pomoże Ci rozwiązać problem i&nbsp;rozwijać się dalej.
          </p>
          </div>
<div className="text-block">
<h3>Konfigurowalność</h3>
<p>

            Konfigurowalność to <strong>możliwość dostosowania systemu do własnych 
            potrzeb.</strong> Najczęściej cecha ta jest oferowana w drogich 
            dedykowanych rozwiązaniach, które zazwyczaj nie są potrzebne małym 
            firmom. Niemniej jednak nawet małe przedsiębiorstwo chciałoby móc 
            dostosowywać poszczególne elementy do swoich potrzeb. Pamiętaj 
            o&nbsp;tym aspekcie przy poszukiwaniu odpowiedzi na pytanie – jaki CRM 
            dla małej firmy?
          </p>
          <h3>Bezpieczeństwo</h3>
          <p>
            Bezpieczeństwo to podstawowa i&nbsp;niezwykle ważna rzecz. Dane, które 
            przechowujesz w systemie są cenną wiedzą i Twoim źródłem przewagi 
            konkurencyjnej. Wybierając CRM sprawdź jakie <strong>rozwiązania w aspekcie 
            bezpieczeństwa</strong> oferuje dostawca – poszukaj odpowiedni informacji 
            na stronie internetowej.
          </p>
          <h3>Wsparcie i edukacja</h3>
          <p>
            Wsparcie od dostawcy i materiały edukacyjne mogą okazać się pomocne 
            podczas wdrożenia oraz na etapie późniejszych prac. Sprawdź, czy firma, 
            z&nbsp;którą podejmujesz współpracę oferuje dodatkową wiedzę na swojej stronie 
            internetowej. <strong>Dostęp do artykułów, porad i filmów 
            instruktażowych</strong> to dobry znak – dzięki temu szybciej poznasz 
            system, a dodatkowo będzie to miejsce poszukiwania inspiracji dla Twojego biznesu. 
          </p>
          <h3>Brak limitów</h3>
          <p>
            Brak limitów nie zawsze jest standardem. Podczas podejmowania decyzji 
            jaki CRM wybrać, możesz natrafić na system, który będzie posiadał kilka 
            pakietów różniących się głównie limitem danych i&nbsp;ceną. Nie wybieraj 
            takiego rozwiązania – <strong>nie płać za rozwój Twojej firmy. Miej 
            swobodę w&nbsp;liczbie obsługiwanych kontrahentów.</strong>
         </p>
        </div>
</div>
</Container>
<Container className="container container--text">
        <h3>Testowanie</h3>
         <p>
            To oczywiste, że <strong>musisz przetestować system</strong> przed zakupem, 
            ale to, na co tutaj warto zwrócić uwagę to przede wszystkim:
        </p>
        <p>
        <li>
          <strong>
            sprawdź, czy system, który testujesz jest wersją demo czy Twoim
            systemem, w którym możesz już od pierwszego dnia wprowadzać dane,
          </strong>
        </li>
        <li>
          upewnij się, że masz szerokie możliwości wyboru – pakietu, liczby
          użytkowników, czasu,
        </li>
        <li>
          sprawdź, czy dostaniesz rabat, jeśli zdecydujesz się na dłuższy okres
          subskrypcji (kto nie lubi rabatów?).
        </li>
      </p>
      <Lightbox
                style={{
                  maxWidth: 600,
                  margin: "0 auto",
                  paddingTop:20,
                }}
                images={[data.kolo]}
                alts={["Cechy CRM"]}
              />
      </Container>
<Container className="container container--text">
      <h2 style={{
      paddingBottom:10}}>Jaki CRM dla małej firmy - kluczowe funkcje</h2>
      <p>
        Cechy to jedno, a funkcje to drugie. One również mają ogromne znaczenie. 
        Oto lista funkcji, które są istotne, kiedy zastanawiasz się jaki CRM wybrać dla małej firmy.
        </p>
                 <h3 style={{
      paddingBottom:10,
      paddingTop:10}}> Baza wiedzy</h3>
<p>
            To podstawowa funkcja systemów CRM, jednak w każdym programie może 
            wyglądać zupełnie inaczej. Mówiąc
            „baza wiedzy”, mamy na myśli wszystkie informacje o&nbsp;kontrahentach.
            Zwróć uwagę na to, czy CRM zbiera je w jednym miejscu np.&nbsp;w&nbsp;karcie
            kontrahenta. Powinna ona zawierać:
            </p>
            <p>
          <ul>
            <li>
              Wszystkie podstawowe informacje o firmie np. adres, NIP, osoby
              kontaktowe.
            </li>
            <li>
              Całą historię kontaktów z danym klientem, czyli zaplanowane
              zadania względem niego, czy dostęp do zdarzeń, czyli
              zrealizowanych kontaktów.
            </li>
            <li>
              Twoje własne atrybuty (cechy) danego kontrahenta - powinna istnieć możliwość
              tworzenia dodatkowych pól np. tekstowych lub list wyboru, które
              informowałby o&nbsp;wybranych przez Cienie aspektach - np.&nbsp;o&nbsp;statusie współpracy.
            </li>
          </ul>
          </p>
          <Lightbox
                style={{
                  maxWidth: 1800,
                  margin: "0 auto",
                  paddingTop:20,
                }}
                images={[data.karta]}
                alts={["Cechy CRM"]}
              />
              </Container>
<Container className="container container--text">

                   <h3 style={{
      paddingBottom:10,
      paddingTop:10}}>Zadania i&nbsp;przypomnienia</h3>
<p>
            CRM powinien pozwalać Ci
           na tworzenie własne typów zadań np. spotkanie z&nbsp;klientem czy dostarczenie
            oferty. Decyzja powinna należeć do Ciebie. Dodatkowo, jeśli
            zadanie pozwala na ustawienie przypomnienia to zdecydowany plus -
            kolejny impuls, który nie pozwoli Ci zapomnieć o zaplanowanym spotkaniu.
</p>
 </Container>
<Container className="container container--text">

                   <h3 style={{
      paddingBottom:10,
      paddingTop:10}}>Zarządzanie uprawnieniami</h3>
<p>
            Z jednej strony ta
            funkcja to bezpieczeństwo, a z drugiej dostosowanie i przejrzystość.
            Bezpieczeństwo, ponieważ to Ty decydujesz o uprawnieniach swoich
            pracowników, czyli o&nbsp;tym, do jakich informacji mają dostęp.
            Dostosowanie i przejrzystość, ponieważ za pomocą uprawnień
            dopasujesz system do swoich własnych potrzeb. Nie musisz widzieć funkcji,
            których nie potrzebujesz w&nbsp;swojej pracy.
</p>
<p
        style={{
          textAlign: "center"
        }}
      ><MultiStepFormButton></MultiStepFormButton></p>
 </Container>
<Container className="container container--text">

                    <h3 style={{
      paddingBottom:10,
      paddingTop:10}}>Kampanie sprzedażowe</h3>
<p>
            CRM powinien posiadać widok szans
            sprzedaży, które uporządkowane są w jednej kampanii, a do Ciebie powinna należeć
            decyzja o: etapach, celach i atrybutach.</p>
            <p>Przy widoku szans sprzedaży zostańmy chwilę dłużej. Chcemy pokazać Ci, 
            jak wiele możliwości dostarcza ta funkcja. Tak wygląda uporządkowany proces 
            sprzedaży w QuickCRM:</p>
            <Lightbox
                style={{
                  maxWidth: 1800,
                  margin: "0 auto",
                  paddingTop:20,
                }}
                images={[data.kanban]}
                alts={["Kanban"]}
              />
      </Container>
<Container className="container container--text">
      <p>
        <strong>Korzyści widoku szans sprzedaży:</strong>
        </p>
        <p>
        <li>
          Informacje o wszystkich szansach są widoczne w jednym miejscu – jedno
          spojrzenie i bardzo szybko otrzymujesz informację o liczbie i wartości
          szans na każdym z etapów. Widzisz, gdzie w Twoim lejku sprzedaży
          pojawia się wąskie gardło, możesz szybko zareagować i usprawnić
          sprzedaż.
        </li>
        </p>
        <p>
        <li>
          Ikony, które znajdują się na szansach informują Cię np. o tym, że nie
          masz zaplanowanego zadania względem tego klienta, przez co, możesz
          utracić szansę na ciekawy kontrakt.
        </li>
        </p>
        <p>
        <li>
          Możesz tworzyć wiele kampanii - nie ograniczasz się do jednego widoku,
          w&nbsp;którym musisz widzieć niezwiązane ze sobą szanse sprzedaży. Przecież
          możesz prowadzić różną sprzedaż, prawda?
        </li>
        </p><p>
        <li>
          Filtrowanie szans według Twoich pracownik zapewni Ci szybką analizę
          ich zaangażowania.
        </li>
        </p>
        <p>
        <li>
          Raporty dla szans tworzone są automatycznie, a Ty oszczędzasz czas,
          który poświęciłbyś na ich stworzenie. O&nbsp;raportach przeczytasz więcej 
          w&nbsp;kolejnym punkcie.
        </li>
        </p>
</Container>
<Container className="container container--text">
       <h3 style={{
      paddingBottom:10,
      paddingTop:10}}>Raporty</h3>

      <p>
        CRM powinien dostarczyć Ci narzędzie do
        analizy skuteczności podejmowanych przez Ciebie działań. Jakie raporty
        powinny znaleźć się w CRM-ie nastawionym na skuteczną sprzedaż?
        </p>
        <p>
        <li>
          <strong>Lejek szans sprzedaży</strong>– idealne narzędzie do analizy
          sprzedaży. Informuje o wartości szans na poszczególnych etapach w
          lejku, a także dostarcza informacji np. o wąskim gardle. Spójrz na
          wykres poniżej. Widzisz coś niepokojącego? To naturalne, że na
          pierwszym etapie jest wiele szans. Na raporcie widzimy, że pomiędzy
          pierwszym, a drugim etapem jest znaczna różnica. To sygnał, aby
          sprawdzić, czy możemy coś poprawić, przyśpieszyć itd.
        </li>
        </p>
        <Lightbox
                style={{
                  maxWidth: 1800,
                  margin: "0 auto",
                  paddingTop:20,
                }}
                images={[data.lejek]}
                alts={["Lejek szans sprzedaży"]}
              />
        </Container>
<Container className="container container--text">
<p>
        <li>
          <strong>Konwersja lejka</strong> – raport pozwalający ocenić efektywność 
          działań prowadzonych na poszczególnych etapach procesu sprzedażowego. 
          Informuje o konwersjach uzyskiwanych podczas poszczególnych faz opieki 
          nad szansami sprzedaży.
        </li>
        </p>
        <Lightbox
                style={{
                  maxWidth: 1800,
                  margin: "0 auto",
                  paddingTop:20,
                }}
                images={[data.konwersja]}
                alts={["Konwersja lejka"]}
              />
        </Container>
<Container className="container container--text">
<p>
        <li>
          <strong>Aktywność handlowców </strong> — raport pozwoli ocenić Ci
          zaangażowanie i&nbsp;skuteczność Twoich pracowników, odpowie na pytanie,
          jakie aktywności podejmowali handlowcy w określonym czasie i wskaże,
          który z nich był najbardziej lub najmniej aktywny.
        </li>
        </p>
        <p>
        <li>
          <strong>Utracone szanse</strong> – to bardziej szczegółowy raport,
          który w formie tabeli prezentuje listę szans (z&nbsp;możliwością przejścia
          do niej i poznania całej historii) z&nbsp;przyczyną utraty, ale także
          szczegółowym komentarzem Twojego pracownika.
        </li>
        </p>

        <p>Przedstawione powyżej funkcje są dobrym wyborem w CRM-ie dla małej firmy. 
        Pamiętaj jednak o&nbsp;przedstawionym wyżej przykładzie Tomka – najważniejsza jest 
        znajomość własnych potrzeb i dobranie takich elementów, które będą dopasowane 
        do rodzaju działalności i sprzyjające tym samym rozwojowi organizacji.
        </p>
        </Container>
<Container className="container container--text">
    <h2 style={{
      paddingBottom:10}}>Podsumowanie</h2>
        <p>
        Skoro zainteresował Cię tak obszerny artykuł to znaczy, że
        zastanawiasz się, jaki CRM będzie najlepszy dla Twojej firmy. Mamy
        nadzieję, że ten artykuł, chociaż trochę pomoże Ci w wyborze. Jeśli masz
        indywidualne pytania, <a href="/kontakt/">napisz do nas</a> – chętnie Ci
        pomożemy. Powodzenia!
      </p>
      <p
        style={{
          textAlign: "center"
        }}
      ><MultiStepFormButton></MultiStepFormButton></p>
      <p>Polecamy także:</p>
      <p>
      <ul>
      <li><Link to="/pierwsze-kroki-w-crm/">Pierwsze kroki w CRM</Link></li>
      <li><Link to="/usprawnianie-procesu-sprzedazy/">Mierzenie i usprawnianie procesu sprzedaży – narzędzia dla managera</Link></li>
<li><Link to="/aplikacja-crm/">Aplikacja CRM. Prowadzenie firmy nigdy nie było tak proste</Link></li>
</ul></p>

      </Container>
    </Artykul>
  );
};

export default JakiCRM;
